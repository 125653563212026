export class EntityStatus {
    constructor(status) {
        this.status = status
    }

    isDraft() {
        return this.status === 'draft'
    }

    isUnPaid() {
        return this.status === 'unpaid'
    }

    isPartPaid() {
        return this.status === 'part-paid'
    }

    isPaid() {
        return this.status === 'paid'
    }

    isDue() {
        return this.status === 'due'
    }
}

export const createEntityStatus = (status) => new EntityStatus(status)
